import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";
import { Home } from "../pages/Home";
import { Header } from "../components/Header";
import { Character } from "../pages/Character";

export function AppRouter() {

    return (
        <>
            <BrowserRouter>
                <Header />
                <div className='main'>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/character/:id" element={<Character />} />
                    </Routes>
                </div>
            </BrowserRouter>
        </>
    )
}