import '../styles/header.css';

export function Header() {
    return (
        <header>
            <div className='first'>
                    <img src='/destino-inteligente.png' alt='Destino Inteligente' />
                    <span>Destino Inteligente SL</span>
            </div>
            <div className='second'>
                <a href='https://rickandmortyapi.com/documentation/#rest' target='_blank'>API Docs</a>
                <a href='https://docs.google.com/document/d/1yoeecUAdwsrIgf7-Hlw5bn7qbpHYg3dk8FwMPr0cHVs/edit?usp=share_link' target='_blank'>PDF Explicación</a>
            </div>
        </header>
    )
}