import React from "react";
import { BASE_URL, CHARACTER_URL } from "../utils/constants";
import { ICharacters } from "../interfaces/ICharacters";
import { CharacterCard } from "../components/CharacterCard";
import '../styles/home.css';

export function Home() {
    const [characters, setCharacters] = React.useState<ICharacters | null>(null);

    React.useEffect(() => {
        getCharacters();
    }, []);

    async function getCharacters(url?: string) {
        const response = await fetch(url ? url : `${BASE_URL}/${CHARACTER_URL}`);
        const data = await response.json();
        setCharacters(data);
    }

    if (!characters) {
        return <div>Loading...</div>
    }

    return (
        <>
            <div className="home-container">
                {
                    characters.results?.map((character, index) => {
                        return (
                            <CharacterCard key={index} character={character} />
                        )
                    })
                }
            </div>

            <div className="pagination">
                {
                    characters.info.prev ? <button onClick={() => getCharacters(characters.info.prev)}>Previous</button> : <button disabled>Previous</button>
                }
                {
                    characters.info.next ? <button onClick={() => getCharacters(characters.info.next)}>Next</button> : <button disabled>Next</button>
                }
            </div>
        </>
    )
}