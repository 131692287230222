import { Link, useParams } from 'react-router-dom';
import React from 'react';
import { BASE_URL, CHARACTER_URL } from '../utils/constants';
import { ISingleCharacter } from '../interfaces/ISingleCharacter';
import { ISingleEpisode } from '../interfaces/ISingleEpisode';
import { ISingleLocation } from '../interfaces/ISingleLocation';
import '../styles/characterPage.css';

export function Character() {
    const [character, setCharacter] = React.useState<ISingleCharacter | null>(null);
    const [episodes, setEpisodes] = React.useState<ISingleEpisode[] | null>(null);
    const [location, setLocation] = React.useState<ISingleLocation | null>(null);
    const { id } = useParams<string>();

    React.useEffect(() => {
        getCharacter();
    }, []);

    async function getCharacter() {
        const response = await fetch(`${BASE_URL}/${CHARACTER_URL}/${id}`);
        const data = await response.json();
        const episodes = data.episode.map(async (episode: string) => {
            const response = await fetch(episode)
            return response.json();
        });
        const resolvedEpisodesJson = await Promise.all(episodes);     
        setEpisodes(resolvedEpisodesJson as ISingleEpisode[]);

        const locationResponse = await fetch(data.location.url);
        const locationData = await locationResponse.json();
        setLocation(locationData);

        setCharacter(data);
    }

    if(!character) {
        return <div>Loading...</div>
    }

    return (
        <div className='character-page'>
            <Link to="/" className='back' style={{textDecoration: 'none'}}>&lt;- Back</Link>
            <img src={character.image} alt={character.name} />
            <div className='content'>
                <h1>{character.name}</h1>
                <p>Status: {character.status}</p>
                <p>Species: {character.species}</p>
                <p>Type: {character.type}</p>
                <p>Gender: {character.gender}</p>
                <p>Origin: {character.origin.name}</p>
                {
                    location && <div className='location-container'>
                        <p>Location: {location.name}</p>
                        <p>Location Type: {location.type}</p>
                    </div>
                }
                <p>Episodes &#40;{character.episode?.length}&#41; : </p>
                <ul>
                    {episodes && episodes.map((episode, index) => {
                        return <li key={index}>
                            <div>
                                <p>{episode.episode}</p>
                                <p>{episode.name}</p>
                                <p>{episode.air_date}</p>
                            </div>
                        </li>
                    })}
                </ul>
            </div>
        </div>
    )
}