import { ResultsEntity } from "../interfaces/ICharacters"
import { Link } from "react-router-dom"
import '../styles/characterCard.css'

export function CharacterCard({ character }: { character: ResultsEntity }) {
    return (
        <Link to={`/character/${character.id}`} style={{ textDecoration: 'none' }}>
            <div className="character-card">
                <img src={character.image} alt={character.name} />
                <h2>{character.name}</h2>
                <div className="description">
                    <div>
                        <p>{character.species}</p>
                        {
                            character.status == "Alive" ? <p>🟢 {character.status}</p> : <p>🔴 {character.status}</p>
                        }
                    </div>
                    <div>
                        {
                            character.gender == "Female" ? <p>♀️ {character.gender}</p> : <p>♂️ {character.gender}</p>
                        }
                        <p>{character.origin.name}</p>
                    </div>
                </div>
            </div>
        </Link>
    )
}